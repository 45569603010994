.bootgrid-header {
    padding: 0 !important;
    margin: 0;

    @include respond(phone) {
        .actionBar {
            padding: 0;
        }

        .search {
            margin: 0 0 10px 0;
        }
    }
}