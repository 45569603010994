 // Variable.scss

 .nestable-cart {
     overflow: hidden;
 }

 .dd-handle {
     color: $white;
     background: $primary;
     border-radius: 0;
     border: 1px solid $border;
 }

 .dd-handle:hover {
     color: $white;
     background: $primary;
 }

 .dd3-content:hover {
     color: $white;
     background: $primary;
 }

 .dd3-content {
     color: $white;
 }