.jsgrid-grid-body,
.dataTables_scrollBody,
.table-responsive {
    &::-webkit-scrollbar {
        background-color: #F5F5F5;
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: $body-color;
    }
}