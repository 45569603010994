.asColorPicker-dropdown {
    max-width: 26rem;
}

.asColorPicker-trigger {
    border: 0 none;
    height: 100%;
    position: absolute;
    left: 99%;
    top: 0;
    width: 2.1875rem;
}

[direction="rtl"] .asColorPicker-trigger {
    right: 99%;
    left: auto;
}

.asColorPicker-clear {
    display: none;
    position: absolute;
    right: 1rem;
    text-decoration: none;
    top: .5rem;
}

.daterangepicker {
    td.active {
        background-color: $primary;

        &:hover {
            background-color: $primary;
        }
    }

    button.applyBtn {
        background-color: $primary;
        border-color: $primary;
    }
}

.datepicker {

    &.datepicker-dropdown {
        background: $l-bg;
        border-radius: 1px;
        border: 1px solid $gallery;

        td.day,
        th.next,
        th.prev {
            height: 30px;
            width: 30px !important;
            padding: 0;
            text-align: center;
            font-weight: 300;
            border-radius: 50px;

            &:hover {
                @extend %gradient-9;
                box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
                color: $white;
            }
        }

        th.datepicker-switch,
        th.next,
        th.prev {
            font-weight: 300;
            color: $heading;
        }

        th.dow {
            font-weight: 300;
        }
    }

    table {



        tr td.selected,
        tr td.active.active {
            @extend %gradient-9;
            box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
            border: 0;
        }

        tr {
            td.today {
                @extend %gradient-9;
                box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
                color: #ffffff;

                &:hover {
                    @extend %gradient-9;
                    box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
                    color: #ffffff;
                }
            }

            td.today.disabled {
                @extend %gradient-9;
                box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
                color: #ffffff;

                &:hover {
                    @extend %gradient-9;
                    box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
                    color: #ffffff;
                }
            }
        }
    }
}


.picker {

    &__select--month,
    &__select--year {
        height: 2.5em;
    }

    &__input {
        background-color: transparent !important;

        @at-root [data-theme-version="dark"] & {
            background-color: transparent !important;
            border: 1px solid $d-border;
        }
    }
}

.asColorPicker-wrap {
    .form-control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}