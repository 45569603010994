.profile-card {
	.title {
		position: relative;

		span {
			display: inline-block;
			text-transform: uppercase;
			background: $white;
			position: relative;
			z-index: 1;
			padding-right: 30px;
		}

		&:after {
			width: 100%;
			height: 1px;
			background: #E7E7E7;
			position: absolute;
			top: 50%;
			transform: translatey(-50%);
			left: 0;
			content: "";
		}
	}

	.form-group {
		margin-bottom: 30px;

		label {
			font-weight: 500;
		}

		.input-icon {
			.form-control {
				padding-left: 70px;
				z-index: 0;

				@include respond('phone-land') {
					padding-left: 50px;
				}
			}
		}

		.input-group-prepend {
			position: absolute;
			top: 50%;
			left: 0px;
			z-index: 1;
			transform: translatey(-50%);

			.input-group-text {
				background: transparent;

				i {
					font-size: 30px;
					color: $primary;

					@include respond('phone-land') {
						font-size: 18px;
					}
				}
			}
		}

		.bootstrap-select.form-control {
			button {
				border-color: #C2C2C2 !important;
				border-width: 0 !important;
				border-bottom-width: 1px !important;
				padding: 10px 0px;
				height: 60px;
				font-size: 18px;
				color: $black;

				@include respond('phone-land') {
					height: 45px;
					font-size: 14px;
				}

				&:hover,
				&:focus {
					border-color: $primary !important;
					border-bottom-width: 2px !important;
				}
			}

			.dropdown-toggle:after {
				color: $primary;
				font-size: 30px;
			}
		}

		.form-control {
			border-color: #C2C2C2;
			border-width: 0;
			border-bottom-width: 1px;
			padding: 10px 0px;
			height: 70px;
			font-size: 18px;
			color: $black;

			&::placeholder {
				color: #BBBBBB;
			}

			&:hover,
			&:focus {
				border-color: $primary;
				border-bottom-width: 2px;
			}

			@include respond('phone-land') {
				height: 45px;
				font-size: 14px;
			}
		}

		textarea.form-control {
			height: unset !important;
			border: 1px solid;
			border-color: #C2C2C2;
			border-radius: $radius;
			color: rgba($black, 0.7);
			background: #FBFBFB;
			border-width: 1px !important;
			font-size: 16px !important;
			padding: 15px 20px !important;
		}

	}
}

.progress-icon {
	height: 62px;
	width: 62px;
	display: block;
	font-weight: 600;
	border-radius: 62px;
	line-height: 62px;
	text-align: center;
	border: 1px solid $border-color;
}

.skill-progress {
	overflow: visible;

	.progress-bar {
		position: relative;
		border-radius: 10px;
		overflow: visible;

		&:after {
			content: "";
			position: absolute;
			height: 26px;
			width: 26px;
			top: 50%;
			transform: translatey(-50%);
			right: 0;
			border-radius: 5px;
			background: $primary;
		}
	}
}